<template>
  <div class="resourcescontent">
    <top-nav @menu_data="getMenuData" :isResourceList="isResourceList" />
    <div class="blank70"></div>
    <div class="resourcescontent_content">
      <div class="first_image_box">
        <img :src="show_article.coverimage+'?x-oss-process=image/resize,m_fill,h_184,w_375'" alt />
        <div class="imgText">
          <div class="text">
            <div class="second_inner_title">{{show_article.title}}</div>
            <div class="second_inner_subtitle">{{show_article.subTitle}}</div>
            <div class="second_inner_time">{{show_article.time}}</div>
          </div>
        </div>
      </div>
      <div class="second_content_box">
        <div class="second_inner_content" v-html="show_article.content"></div>
      </div>
      <div class="back">
        <p class="backText" @click="goRouter('/Blogs')">Back to blogs
        </p>
      </div>
      <div class="third_content_box">
        <div class="third_title">Related blogs</div>
        <div class="third_item_box" v-for="(d,i) in resourceList3" :key="i" @click="goRouter('/BlogDetails/'+resourceList3[i].webblockid)">
          <div class="left_img_box">
            <img :src="d.coverimage+'?x-oss-process=image/resize,m_fill,h_113,w_164'" alt />
          </div>
          <div class="right_content_box">
            <div class="text">
              <div class="upper">{{d.title}}</div>
              <div class="lower">{{d.subtitle}}</div>
              <div class="three">{{handleAmPmDate(d.updatedtimestamp)}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_link">
        
        <!-- <span>Copyright 2021 © Arcanite</span>
        <span @click="goRouter('/privacyPolicy')">Privacy Policy</span> -->
      </div>
    </div>
    <footer-nav />
    <menu-bar :isShow="isShow" @changeIsShow="changeShow" />
  </div>
</template>

<script>
import { getWebBlock } from "@/api/userApi.js";
import FooterNav from './FooterNav.vue';
import { AmPmDateNoTime } from '@/assets/js/time.js'
let moment = require("moment")
export default {
  data() {
    return {
      isShow: false,
      webblockid: "",
      resourceList: [],
      resourceList3: [],
      show_article: {
        coverimage: "",
        title: "",
        content: "",
        subTitle:"",
        time:"",
      },
      isResourceList: true
    };
  },
  created() {
    this.webblockid = this.$route.params.id;
    getWebBlock().then(res => {
      if (res.data.success) {
        this.resourceList = res.data.data;
        this.resourceList3 = this.resourceList.slice(0, 3);

        this.resourceList.forEach(e => {
          if (e.webblockid == this.webblockid) {
            this.show_article.content = e.content;
            this.show_article.title = e.title;
            this.show_article.coverimage = e.coverimage;
            this.show_article.subTitle=e.subtitle;
            this.show_article.time=moment(e.updatedtimestamp).format('MMM DD, YYYY');
          }
        });
      }
    });
  },
  watch: {
    $route(to, from) {
      location.reload();
    }
  },

  methods: {
    goRouter(path) {
      this.$router.push(path);
    },
    getMenuData(val) {
      val == "1" ? (this.isShow = true) : (this.isShow = false);
    },
    changeShow() {
      this.isShow = false;
    },
    handleAmPmDate(times) {
      return AmPmDateNoTime(times)
    }
  },
  components: {
    "top-nav": resolve => require(["./TopNav.vue"], resolve),
    "footer-nav": resolve => require(["./FooterNav.vue"], resolve),
    "menu-bar": resolve => require(["./MenuBar.vue"], resolve)
    // FooterNav
  }
};
</script>
<style lang="scss">
.resourcescontent {
  .blank70 {
    height: 70px;
  }
  .resourcescontent_content {
    background-color: #f7f7f7;
    box-sizing: border-box;
    min-height: calc(100vh - 103px);
    text-align: left;
    .first_image_box {
      // height: 184px;
      width: 100vw;
      height: 49vw;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .imgText{
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        top:0;
        .text{
          position: absolute;
          left: 26px;
          bottom:26px;
        }
        .second_inner_title {
            font-family: Poppins;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
        }
        .second_inner_subtitle {
            flex-grow: 0;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          padding-top: 4px;
        }
        .second_inner_time {
            flex-grow: 0;
            font-family: Poppins;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            padding:10px 0 0 0;
        }
      }
    }
    .second_content_box {
      box-sizing: border-box;
      width: 100%;
      // height: 336px;
      padding: 25px 24px 0;
      .second_inner_content {
          font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #314455;
        // padding: 0 0 24px;
        // border-bottom: solid 0.5px #e6ebf2;
        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
        }
      }
    }
    .third_content_box {
      // width: 100%;
      margin: 0 23px 0 23px ;
      padding: 24px 0 24px 0;
      border-top: solid 0.5px #e6ebf2;
      .third_title {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #67788c;
      }
      .third_item_box {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        .left_img_box {
          width: 164px;
          height: 113px;
          overflow: hidden;
          border-radius: 5px;
        }
        .right_content_box {
          margin-left: 15px;
          flex: 1;
          height: 113px;
          position: relative;
          .text{
            .upper {
              font-family: Poppins;
              font-size: 14px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.43;
              letter-spacing: normal;
              text-align: left;
              color: #062440;
              overflow: hidden;
              -webkit-line-clamp:2;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
            .lower {
              margin-top: 8px;
              font-family: Poppins;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.43;
              letter-spacing: normal;
              text-align: left;
              color: #314455;
              overflow: hidden;
              -webkit-line-clamp:2;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
            .three {
              font-family: Poppins;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: #67788c;
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
    }
    .bottom_link {
      margin-top: 20px;
      margin-left: 24px;
      padding-bottom: 24px;
      font-family: Arial;
      font-size: 12px;
      color: #9b9b9b;
      text-align: left;
      span {
        margin-right: 25px;
      }
    }
  }
  .back{
    width: 96px;
    margin:26px;
    margin-left: 24px;
      padding-bottom:10px;
      border-bottom: 2px solid #67788c;
    p{
      flex-grow: 0;
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #67788c;
    }
	.backText{
		white-space: nowrap;
	}
  }
}
</style>